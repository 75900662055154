export const logo = "https://firebasestorage.googleapis.com/v0/b/icai-dhn.appspot.com/o/Logo%2F12066-removebg-preview.png?alt=media&token=b801c7c3-d505-44b6-8c1d-9d00e172df40"

export function  removeSpaces(str){
    return str.replaceAll(/\s/g,'')
}

export function toTitleCase(str) {
    if(str!="" && typeof(str)=="string"){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );} else return str
  }