
import React, { useCallback, useContext,useEffect,useState } from "react";
import { Fragment } from "react";
import { withRouter, Redirect,useHistory } from "react-router";
import app from "../firebase";
import { AuthContext } from "./Auth.js";
import './Login.css'
import "../View.css"

import Carousel from 'react-material-ui-carousel'
import { logo } from "../Common/CommonVarFunc";
import axios from "axios";
const Login = ({ history }) => {
  const a = useHistory();
  const [showPassword, setShowPassword] = useState(false)
  const [password1, setPassword1] = useState("")
  const [images, setImages] = useState({})

  useEffect(()=>{
    const ref = app.firestore().collection("website").doc("sidepanel")
    ref.get().then((doc)=>{
        setImages(doc.data())
    })
   
    })

  
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {

      app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
  .then(() => {    
      setPassword1(password.value)
     return app.auth().signInWithEmailAndPassword(email.value, password.value);

  }).catch((error)=>{
    // const arr = error.message.split(":")
    window.alert(error.message)
  })
  
       
      } 
      catch (error) {
         window.alert(error.message);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);
  const UpdatePasswordOnLogin=(x)=>{
    const users = app.firestore().collection("users").doc(currentUser.uid);
    //let map = new Map([mid,"r"])
   users.update({
     password:x
   }).then(function(){

   }).catch(function(error){
    
   })
  }


  if (currentUser) {
    UpdatePasswordOnLogin(password1)
  
    return <Redirect to="/" />;
  }

  return (   
    <Fragment>
      <div> 
     
          <section>
         <div className="imgBx">
           <Carousel className="carousel" indicators={false} > 
           {Object.values(images).map((doc)=> <div><img src={doc}/> </div>)}
      
             </Carousel>
         </div>
         <div className="contentBx">
           <div className="formBx">
           <center>   <h2>DHANBAD BRANCH of  </h2> <br/>
           <h2>CIRC of ICAI</h2><br/>
       <h2>Messaging Portal</h2> </center>
            {/* <center><h2>Login</h2></center>  */}
             <form onSubmit={handleLogin}>
               <div className="inputBx">
                 <span>Email</span>
                 <input type="email" name="email" /> 
               </div>
               <div className="inputBx">
                 <span>Password</span>
                 <input type={showPassword ?"text":"password" }name="password" /> 
               </div>
               <div className="remember">
                 <label style={{color:"black"}}>  <input type="checkbox" name="" onChange={()=> setShowPassword(!showPassword)} /> Show Password</label>
               </div>
               
               <div className="inputBx">
                 
                 <input type="submit" value="Sign In" /> 
               </div>
               <div className="remember">
                 <label style={{color:"black", fontSize:"15px"}} >  © ARPIT - By <a href="https://pankhudisoftware.com/" style={{textDecoration:"none",color:"black"}}>Pankhudi Software</a></label>
               </div>
            
            
             </form>
           </div>
         </div>
       </section>
       </div> 
       
       
      
    </Fragment>
  );
};

export default withRouter(Login);


   
